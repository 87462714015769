import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <section className="section bg-coral-pink text-cloud-white">
      <div className="grid grid-cols-12 gap-x-10 space-y-12">
        <div className="col-start-2 col-end-13">
          <h1 className="max-w-sm text-4xl font-bold uppercase tracking-wide text-cloud-white lg:max-w-3xl lg:text-6xl xl:text-7xl">
            404: Not Found
          </h1>
        </div>
        <div className="col-start-3 col-end-13 lg:col-start-4 lg:col-end-10">
          <div className="rte">
            <p>
              Sorry, we can't find that page. <Link href="/">Click here</Link>{" "}
              to go back home.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
